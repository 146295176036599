import { Lang } from '@/types/locales';

import { I18nLang } from './types';

export const DEFAULT_NAMESPACE = 'messages';

export const LANG_TO_I18N_LANG_MAP: Record<Lang, I18nLang> = {
  'en-au': 'en',
  'en-ca': 'en',
  'en-hk': 'en',
  'en-nz': 'en',
  'fr-ca': 'fr',
  'zh-hk': 'zh',
};
