import { createInstance, i18n, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import i18nConfig from '@/i18nConfig';
import { Lang } from '@/types/locales';

import { resourceResolver } from './utils';
import { LANG_TO_I18N_LANG_MAP } from './consts';

const languages = Array.from(new Set(i18nConfig.locales.map((locale) => locale.split('-')[0])));

export default async function initTranslations(
  locale: Lang,
  i18nInstance?: i18n,
  resources?: Resource,
) {
  i18nInstance = i18nInstance || createInstance();

  i18nInstance.use(initReactI18next);

  if (!resources) {
    i18nInstance.use(resourcesToBackend(resourceResolver));
  }

  const defaultLang = i18nConfig.defaultLocale.split('-')[0];

  await i18nInstance.init({
    lng: LANG_TO_I18N_LANG_MAP[locale] || defaultLang,
    resources,
    fallbackLng: defaultLang,
    supportedLngs: languages,
    preload: resources ? [] : languages,
  });

  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t,
  };
}
