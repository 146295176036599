import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/SWRProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/atoms/Link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/molecules/Menu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/i18n/provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@headlessui/react/dist/components/menu/menu.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/vercel/path0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"Figtree\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"figtree\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/add.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/arrow-swap-horizontal.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/banner-background-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/btc.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/carousel-arrow-next.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/carousel-arrow-previous.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/chevron-left.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/chevron-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/confirmed-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/directions.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/document-copy.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/dollar-bill.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/error-circle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/currency-circle-dollar.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/settings.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/shield-security.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/trend-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/fees/trend-up.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/flags/australia.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/flags/canada.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/flags/french-quebec.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/flags/hong-kong.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/flags/new-zealand.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/info.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/interac-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/logo-big.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/map-pin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/medal-star.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/message-question.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/minus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/order-error.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/reddit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/socials/twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/svg/terms-of-service-map.svg");
