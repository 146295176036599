'use client';

import React, { PropsWithChildren } from 'react';
import { createInstance } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import initTranslations from './init';
import { TranslationProviderProps } from './types';
const TranslationProvider = React.memo<PropsWithChildren<TranslationProviderProps>>(({
  children,
  locale,
  resources
}) => {
  const i18n = createInstance();
  initTranslations(locale, i18n, resources);
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
});
TranslationProvider.displayName = 'TranslationProvider';
export default TranslationProvider;